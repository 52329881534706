<template>
  <section class="team">
    <div class="top-menu-button"><Menu /></div>
    <div class="title-container">
      <h1 class="team-header font-team">Zespół</h1>
      <div class="title-right-line"></div>
      <div class="title-underline"></div>
    </div>

    <div style="clear: both;"></div>
    <div class="team-container">
      <div class="team-member">
        <div class="team-member-left">
          <div class="st-left-line"></div>
          <div class="team-profile-image" v-fade>
            <img src="@/assets/img/przemek-profile.jpg" />
          </div>
          <div class="team-front-image" v-fade-two>
            <img src="@/assets/img/przemek-front.jpg" />
          </div>
          <div class="team-member-role">
            <span class="name">PRZEMEK </span><br /><span class="title"
              ><span class="text-nowrap">SCRUM MASTER</span>
          </div>
          <div class="st-member-underline"></div>
        </div>
        <div class="team-member-right">
          <div class="team-member-text ">
            W naszej agencji Przemek odpowiada za to, aby Scrum był rozumiany i stosowany.
            Innymi słowy zapewnia, że zespól Scrumowy stosuje się do
            teorii Scruma (czyli empirycznej kontroli procesu), oraz reguł i praktyk (wydarzenia, role oraz artefakty).
          </div>
          <div class="team-member-icon" v-fade-three>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-briefcase-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"
              />
              <path
                d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="team-member">
        <div class="team-member-left">
          <div class="nd-left-line"></div>
          <div class="menu-button-left"><Menu /></div>
          <div class="team-profile-image" v-fade>
            <img src="@/assets/img/jakub-profile.jpg" />
          </div>
          <div class="team-front-image" v-fade-two>
            <img src="@/assets/img/jakub-front.jpg" />
          </div>
          <div class="team-member-role v-scrollanimation">
            <span class="name">JAKUB </span><br /><span class="title"
              >FULLSTACK<br />
              DEVELOPER</span
            >
            <div class="nd-member-underline"></div>
          </div>
        </div>
        <div class="team-member-right">
          <div class="team-member-text">
            Każda agencja interaktywna potrzebuje swoich developerów, u nas
            jednym z nich jest Jakub, który odpowiada za dostarczane
            oprogramowanie dla naszych klientów. Zakoduje dla Ciebie wymarzoną
            stronę czy też w pełni funkcjonalny sklep.
          </div>
          <div class="team-member-icon" v-fade-three>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-bezier2"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 2.5A1.5 1.5 0 0 1 2.5 1h1A1.5 1.5 0 0 1 5 2.5h4.134a1 1 0 1 1 0 1h-2.01c.18.18.34.381.484.605.638.992.892 2.354.892 3.895 0 1.993.257 3.092.713 3.7.356.476.895.721 1.787.784A1.5 1.5 0 0 1 12.5 11h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5H6.866a1 1 0 1 1 0-1h1.711a2.839 2.839 0 0 1-.165-.2C7.743 11.407 7.5 10.007 7.5 8c0-1.46-.246-2.597-.733-3.355-.39-.605-.952-1-1.767-1.112A1.5 1.5 0 0 1 3.5 5h-1A1.5 1.5 0 0 1 1 3.5v-1zM2.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10 10a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="team-member team-member-two">
        <div class="team-member-left">
          <div class="rd-left-line"></div>
          <div class="menu-button-left"><Menu /></div>
          <div class="team-profile-image">
            <img src="@/assets/img/filip-profile.jpg" v-fade />
          </div>
          <div class="team-front-image" v-fade-two>
            <img src="@/assets/img/filip-front.jpg" />
          </div>
          <div class="team-member-role">
            <span class="name">FILIP </span><br /><span class="title"
              >FULLSTACK<br />
              DEVELOPER</span
            >
          </div>
          <div class="st-member-underline"></div>
        </div>
        <div class="team-member-right">
          <div class="team-member-text">
            Filip wraz z Jakubem, zajmuje się techniczą stroną naszych
            realizacji. Dodatkowo zadba o pozycjonowanie Twojej strony w
            wyszukiwarkach internetowych.
          </div>
          <div class="team-member-icon" v-fade-three>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-bezier"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
              />
              <path
                d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="space-100px"></div>
      <div class="team-member team-member-two">
        <div class="team-member-left">
          <div class="rd-left-line"></div>
          <div class="menu-button-left"><Menu /></div>
          <div class="team-profile-image">
            <img src="@/assets/img/kacper-profile.jpg" v-fade />
          </div>
          <div class="team-front-image" v-fade-two>
            <img src="@/assets/img/kacper-front.jpg" />
          </div>
          <div class="team-member-role">
            <span class="name">KACPER </span><br /><span class="title"
              >UX/UI<br />
              DESIGNER</span
            >
          </div>
        </div>
        <div class="team-member-right">
          <div class="team-member-text">
            Kacper w WebVision.Art zajmuje się grafiką i projektowaniem stron
            internetowych w taki sposób, aby były miłe dla oka, a korzystanie z
            nich było intuicyjne i przyjemne. Dba o jak najlepsze spełnienie
            wymagań graficznych Twoich użytkowników.
          </div>
          <div class="team-member-icon" v-fade-three>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              fill="currentColor"
              class="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <footerm />
  </section>
</template>

<script>
import footerm from "@/components/footer.vue";
import Menu from "@/components/MenuBurger.vue";
export default {
  name: "Projects",
  components: {
    Menu,
    footerm
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/Team/_Team.scss";
@import "@/assets/styles/Team/responsive/xlarge_D.scss";
@import "@/assets/styles/Team/responsive/large_D.scss";
@import "@/assets/styles/Team/responsive/medium_D.scss";
@import "@/assets/styles/Team/responsive/small_D.scss";
@import "@/assets/styles/Team/responsive/xsmall_D.scss";
</style>
