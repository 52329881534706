<template>
  <div class="page-view">
    <Team />
  </div>
</template>

<script>
import Team from "@/components/Team.vue";
export default {
  name: "team",
  components: {
    Team
  },
  methods: {
    scroll: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  beforeMount: function() {
    setTimeout(() => this.scroll(), 100);
  }
};
</script>
